/* 投資実績一覧PC版のスタイル */
.companies_list_swiper__pc {
  width: 90%;
  max-width: 900px;
  margin:0;
}

.companies_list_swiper__sp {
  max-width: 100%;
}

.company_list_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 155px;

  &:hover {
    outline: 1px solid black;
  }
}

/* 企業一覧のカテゴリータグのスタイル */
.company_tag {
  height: 35px;
  width: auto;
  position: absolute;
  top: -1px;
  left: -1px;
}

.swiper_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.swiper_slide__pc {
  background-color: white;
  height: 471px;
}

.swiper_slide__sp {
  background-color: white;
  height: 629px;

}

.link_to_company {
  text-decoration: none;
  color: black
}