.header__pc {
    line-height: 0.9;
    border-image: linear-gradient(
        to right,
        #54b2db 0%,
        #2557af 100%
        )  1/0 0 3px 0;
    border-style: solid;
    padding: 50px 0px 20px 0px;
    margin: 50px auto;
}
    
.header__sp {
    line-height: 0.9;
    border-image: linear-gradient(
        to right,
        #54b2db 0%,
        #2557af 100%
        )  1/0 0 3px 0;
    border-style: solid;
    padding: 50px 10px 20px 10px;
    margin:  auto;
}

.container__pc {
    padding: 30px 0px;
}

.container__sp {
    padding: 30px 10px;
}

.content {
    padding-bottom: 50px;

    &__list {
        padding-top: 25px;
    }
}

a {
    text-decoration: none;
    color:#54b2db;
}