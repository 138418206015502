// 色
$blue: #2557af;
$black: #000000;
$list-color: #f4f9fa;

.table_row:nth-child(odd)  {
    /* リストを交互に色付けしている */
    background-color: $list-color;
}

th {
    /* テーブル内のpadding */
    padding: 25px 16px !important;
    width: 130px;
}

a:hover {
    text-decoration: none;
    color: $blue;
}

ul {
    /* リストpadding初期値を修正している */
    padding-inline-start: 20px;
    font-family: source-han-sans-japanese, sans-serif;
    font-size: 16px;

    li {    

        &::marker {
            color: $blue
        }
        
        span {
            /* リストの文字サイズと文字色 */
            color: $black;
        }
    }
}



.map_chip {
    font-size: 12px;
    text-decoration: none;
    color: #fff;
    background-color: #2557af;
    padding: 4px 18px;
    border-radius: 20px;
    margin-left: 8px;
  
    &:hover {
        transition: all 0.2s;
        background-color: #193c7a;
        color:#fff;
    }
}
