// colors 
$gradient-contact-btn: linear-gradient(150deg,
  #c6ceef 0%,
  #4597e3 50%,
  #378fdd 50%,
  #2f4fa9 100%
);

$gradient-contact-btn_hover: linear-gradient(
  150deg,
  #3b63d1 0%,
  #1f5a92 50%,
  #3357b9 50%,
  #2f4fa9 100%
);


$gradient-contact-btn_res: linear-gradient(
  90deg,
  #53b0dbe0 0%,
  #2f4fa9 80%
);

$blue: #2557af;


.container {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content:space-between;
  box-shadow: 2px 1px 41px -16px #05030336;
  z-index: 10;
}

/* ロゴのスタイル */
.logo {
  margin: 0px 15px;
  padding-top: 10px;
  height: 40px;
  width: auto;
}

/* メニューアイテム */
.menu_item {
  color: black;
  background-color: #fff;
  font-weight: bold;
  text-decoration: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 20px;
  transition: all 0.25s ease;

  &:hover {
    color: $blue;
    transition: all 0.25s ease;
  }

  &__home {
    background-color: #fff;
    font-weight: bold;
    text-decoration: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 20px;
    text-decoration: none;
    color: black;
    transition: all 0.25s ease;
  
    &:hover {
      color: $blue;
      transition: all 0.25s ease;
    }
  }
}



/* PCお問い合わせボタン */
.pc_contact_btn {
  color: #fff;
  padding: 21px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  outline: none;
  background: $gradient-contact-btn;

  &:hover {
    color: white;
    background: $gradient-contact-btn_hover;
  }
}


/* メニューレスポンシブ */
.menu_box__res {
  padding: 22px;
  text-align: center;
  color: 'black';
  height: 70px;
  
}


.menu_item__res {
  text-decoration: none;
  color:black;
  font-weight: bold;
  margin: 16px 0px;
  font-size: 17px;
}

/* ドロワー */
.toggle_btn__open {
  border: none;
  outline: none;
  background-color: #fff;
  height: 60px;
  padding-bottom: 2px;
  margin-right: 15px;
}

.toggle_btn__close {
  border: none;
  outline: none;
  background-color: #fff;
  padding: 10px 20px;
  margin-top: 10px
}


.drawer_menu_list {
  width: 270px;
}

/* レスポンシブお問合せボタン */
.contact_button__res {
  font-weight: bold;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 30px;
  padding: 12px 60px;
  text-decoration: none;
  background: $gradient-contact-btn_res;
}


.privacy_policy {
  text-decoration: none;
  color: black;
}