/* Propsとして指定されているスタイルの定義 */
.white {
    color: white
}

.gradient {
    background: linear-gradient(
        90deg,
        #54b2db,
        #2557af 
      );
    display: inline;
    background-clip: text;
    color: transparent;
}