/* トップ画像のスタイル */
.support_image {
    height: auto;
    width: 100%;
    z-index: 1;
    left:0;
    top: 0;

    &__bg {
        position: absolute;
        width: 85%;
        height: auto;
        bottom: 0;
        right: 0;
        z-index: -10;
    }
}



/* コンタクトボタンのスタイル */
.support_contact_btn {
    font-weight: bold;
    border: none;
    outline: none;
    color: #fff;
    margin-top: 50px;
    width: 280px;
    padding: 10px 16px;
    border-radius: 50px;
    background: linear-gradient(
        90deg,
        #54b2db,
        #2557af 
    );

    span {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &:hover {
        background: #2557af;
    }
}