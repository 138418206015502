.content {
    /* 電話番号とFAXのスタイル */
    display: flex;
    align-items: center;
    span {
        text-align: center;
        font-size: 0.5em;
        margin-right: 10px;
        background-color: white;
        border-radius: 3px;
        width: 45px;
        color: #2557af;
        
    }
}

.mailer_btn__pc {
    font-weight: bold;
    border: none;
    outline: none;
    font-size: 0.8em;
    padding: 12px 24px;
    border-radius: 24px;
    fill: #2557af ;
    color: #2557af ;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        background-color: #2557af;
        color: #fff;
        fill: #fff;
    }
}

.tel_btn {
    text-decoration: none;
}

.mailer_btn__sp {
    width: 280px;
    font-weight: bold;
    border: none;
    outline: none;
    font-size: 0.8em;
    padding: 12px 18px;
    border-radius: 24px;
    color: #2557af;
    fill:#2557af;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

svg.mailer_icon {
    height: 1.3em;
    width: auto;
    margin-top: 1px;
    margin-left: 4px;
}

svg.mailer_icon:hover{
    fill: white ;
    stroke: white ;
}
