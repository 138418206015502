// colors
$gradient: linear-gradient(
  90deg,
  #53b0dbe0 10%,
  #004f8f 140%
);


/* キャッチフレーズ */
.company_catch_phrase {
  font-size: 25px;
  font-weight: bold;
  padding: 6px 22px;
  color: white;
  background: $gradient;
  margin-bottom: 8px;
  opacity: 0.9;
  span {
    font-size: 1.6em;
  }

  &__sp {
    font-size: 22px;
    font-weight: bold;
    padding: 4px 10px;
    color: white;
    background: $gradient;
    margin-bottom: 8px;

    span {
      font-size: 1.6em;
    }
  }
}



/* トップ画像のスタイル */
.catch_image {
  overflow: hidden;
  right: 0;
  margin-left: -37px;
  width: 100%;
  height: 550px;
  object-fit: cover;
}
