// colors
$white: #f3f9f9;
$blue: #2557af;

.arrow_btn__pc {
    background-color: $white;
    outline: none;
    border: none;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    margin: auto;
    margin: 20px;
    cursor: pointer;
    &:hover {
        background: $blue;
        fill: $white;
        svg {
            fill: $white;
        }
    }
}

.arrow_btn__mobile {
    background-color: $white;
    outline: none;
    border: none;
    border-radius: 20px;
    height: 40px;
    width: 40px;
}

