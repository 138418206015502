.container {
    background-color: #f4f9fa;
    padding: 52px 20px;
}

.group_flag {
    position: absolute;
    left: 0;
    top: 0;
    height: 35px
}

.group_img {
    @media screen and (min-width: 799px) {
        border-radius: 4px 0 0 4px;
    }
}

.group_link {
    display: flex;
    @media screen and (max-width: 899px) {
        flex-direction: column;
    }
}

.group_card {
    margin-inline:auto;
    background-color: white;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    position: relative;
    max-width: 847px;
    box-shadow: 0px 0px 10px #0000001C;;
    &:hover {
        opacity: 0.8;
        transition: all 0.2s;
        .group_heading {
            color:#67A8D2;
        }
        .icon_container{
            background-color:#67A8D2 ;
        }
    }
}

.group_content {
    padding:  30px 20px 15px;
    font-size: 14px;
    line-height: 1.8;
    @media screen and (max-width: 799px) {
        padding:22px 13px ;
        font-size:13px ;
    }
} 

.group_heading {
    color: #2557af ;
    margin-left: 10px;
    font-size: 19px;
    @media screen and (max-width: 799px) {
      font-size: 18px;
    }
}

.icon_container {
    width: 24px;
    height: 24px;
    background-color:#2557af;
    border-radius: 50%;
    position: relative;
}

.icon_container {
    width: 24px;
    height: 24px;
    background-color:#2557af;
    border-radius: 50%;
    position: relative;
}
.arrow_right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.group_heading_content {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.group_description {
    color:#000;
}