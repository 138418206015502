.container {
    background-color: #f4f9fa;
    padding: 52px 20px;
}

.news_flag {
    position: absolute;
    left: 0;
    top: 0;
    height: 35px
}

.news_card {
    display: flex;
    padding: 35px 40px 25px 40px;
    margin: auto;
    background-color: white;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    position: relative;
    justify-content: space-around;
    box-shadow: #d7d7d7 0px 0px 8px 0px
}

.news_content_list {
    display: flex;
    width: 100%;
    margin-bottom: 9px;
    margin-top: 9px;
    padding-bottom: 9px; 
    border-bottom: 2px solid #f0f0f0;

    &:last-child {
        border: none;
    }
}

.news_date {
    padding-right: 10px;
}
