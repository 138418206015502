/* ポートフォリオセクションスタイル */
.container {
  color: white;
  background: linear-gradient(
    90deg,
    #54b2db,
    #2557af 
  );
}

/* 矢印ボタン */
.arrow_btn {
  background-color: "white";
  height: "40px";
  width: "40px";
  padding: "10px";

  &__left {
    height: "14px";
    margin-right: "3px";
    transform: "rotate(180deg)";
  }

  &__right {
    height: "14px";
    margin-left: "3px";
  }
}


/* お問合せボタンスタイル */
.contact_btn {
  background-color: white;
  border: none;
  outline: none;
  width: 240px;
  padding: 16px;
  border-radius: 50px;

  &:hover {
    background-color: #2557af ;
    color: #fff;

    svg {
      fill: #fff
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    font-weight: bold;
    font-size: 15px;
    padding-left: 5px;
    padding-top: 1px;
  }
}


.arrow_icon {
  height: 14px;
  width: auto;
  fill: #2557af
}