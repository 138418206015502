footer{
  /* Footer全体のスタイル */
  color: white;
  background: linear-gradient(
    90deg,
    #54b2db,
    #2557af 
  );

  .logo {
    margin: 0px 15px;
    padding-top: 10px;
    height: 40px;
    width: auto;
  }

  .text {
      /* フッターメニュー文字のスタイル */
      font-size: 15px;
      padding: 12px;
      text-decoration: none;
      color: #fff;

      &:hover {
        text-decoration: underline;
        color: #fff;
      }
  }

  .floating_btn {
    /* フローティングボタンのスタイル */
    position: sticky;
    left: 250vh;
    bottom: 40vh;
    margin-top: 30px;
    margin-right: 30px;
    display: inline-block;
  }
}

